/**
 * Main project app script!
 *
 * All script functionality should be in this file.
 *
 * NOTE: jQuery by default is NOT supported! Please make sure to follow ES6 paradigms and create vanilla JS
 * wherever possible. jQuery is included for the sake of plugins that we rely on such as Gravity Forms.
 * Refer to the document at https://wiki.posturedev.com for further information.
 **/

//Modules
// import { Glightbox, AOS, Swiper } from "Parent/js/modules/modules";

// All generic global site javascript should live in this file.
import Globals from "./globals";
import HomeSlider from "./lib/home-slider";
import BetterMenu from "./lib/better-menu/better-menu";
import BetterModal from "./lib/better-menu/better-modal";
import SuperActiveAnchor from "./lib/super-active-anchor";
import PartnerSlider from "./lib/partner-slider";

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	document.querySelector("body").setAttribute("data-s-loaded", "");

	// Remove and uncomment the enqueue script in functions.php if not needed
	window.$ = jQuery();
	const betterMenu = new BetterMenu();
	new BetterModal();
	new SuperActiveAnchor();
	new PartnerSlider();
	const globals = new Globals();

	new HomeSlider();



	function getId(url) {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);

		return (match && match[2].length === 11)
			? match[2]
			: null;
	}

	const videoWrap = document.querySelector(".video-wrap");

	if (videoWrap) {
		const videoId = getId(videoWrap.dataset.video);
		const iframeMarkup = '<iframe src="//www.youtube.com/embed/'
			+ videoId + '" frameborder="0" allowfullscreen></iframe>';

		videoWrap.innerHTML = iframeMarkup;
	}

});
