function dropdownHandler(menu, context) {
	const dropdowns = menu.querySelectorAll("li > ul");

	for (let i = 0; i < dropdowns.length; i++) {
		const dropdown = dropdowns[i];

		const parent = dropdown.parentNode;
		const dropdownAnchor = parent.querySelector("a");
		const menuType = menu.getAttribute("data-better-menu");

		// Add some pointers to make code targeting easier
		parent.setAttribute("data-bm-has-submenu", i);
		dropdownAnchor.setAttribute("data-bm-dropdown-anchor", "");

		const dropdownArrow = document.createElement("i");
		dropdownArrow.setAttribute("data-bm-dropdown-arrow", "");
		dropdownArrow.insertAdjacentHTML("afterbegin", context.dropdownArrow);
		dropdownAnchor.insertAdjacentElement("beforeend", dropdownArrow);

		context.registerMenuEvent(menu, "bm-dropdown-open", dropdownAnchor, openDropdownEvent);
		context.registerMenuEvent(menu, "bm-dropdown-close", dropdownAnchor, closeDropdownEvent);

		// Accessibility
		dropdownAnchor.setAttribute("aria-expanded", "false");
		dropdownAnchor.setAttribute("aria-haspopup", "true");

		// Initial base listeners
		dropdownAnchor.addEventListener("click", dropdownEventHandler.bind(context));

		// Horizontal mouse enter listener
		if (menu.hasAttribute("data-bm-hoverable-dropdowns") && !context.isMobileDevice) {
			parent.addEventListener("mouseenter", dropdownHoverableEventHandler.bind(context));
		}
	}
}

function dropdownEventHandler(event) {
	// Prevent immediate linking!
	event.preventDefault();
	const parent = event.target.closest("li");
	const anchor = parent.querySelector("a");

	// Check to make sure the arrow wasn't clicked
	if (event.target.nodeName.toLowerCase() === "i") {
		if (anchor.hasAttribute("data-bm-active")) {
			anchor.dispatchEvent(this.events["bm-dropdown-close"]);
		} else {
			anchor.dispatchEvent(this.events["bm-dropdown-open"]);
		}
	} else {
		const dropdown = anchor.parentNode.querySelector("ul");
		const menu = anchor.closest("[data-better-menu]");
		const isMobile = this.isMobileDevice;

		// mini link handler function
		const linkHandler = () => {
			const anchorLink = anchor.getAttribute("href");
			const anchorTarget = anchor.getAttribute("target");

			if (anchorTarget) {
				window.open(anchorLink);
			} else {
				window.location.href = anchorLink;
			}
		};

		if (dropdown.hasAttribute("data-bm-active")) {
			anchor.dispatchEvent(this.events["bm-dropdown-close"]);
		} else {
			anchor.dispatchEvent(this.events["bm-dropdown-open"]);
		}

		// Click Handling
		if (anchor.hasAttribute("data-bm-clicked-once")) {
			linkHandler();
		} else {
			anchor.setAttribute("data-bm-clicked-once", "");
		}

		// Desktop with hoverable dropdowns handler
		if (!isMobile && menu.hasAttribute("data-bm-hoverable-dropdowns")) {
			linkHandler();
		}
	}
}

function dropdownHoverableEventHandler(event) {
	const parent = event.target;
	const parentID = parent.getAttribute("data-bm-has-submenu");
	const menu = parent.closest("[data-better-menu]");
	const anchor = parent.querySelector("a");
	const dropdown = parent.querySelector("ul");

	if (this.dropdownTimers[menu.id]) {
		if (this.dropdownTimers[menu.id][parentID]) {
			clearTimeout(this.dropdownTimers[menu.id][parentID]);
		}
	} else {
		this.dropdownTimers[menu.id] = {};
	}


	// Only run when device is desktop. Let the click handler deal with this event
	if (!this.isMobileDevice) {
		anchor.dispatchEvent(this.events["bm-dropdown-open"]);

		parent.addEventListener(
			"mouseleave",
			(event) => {
				this.dropdownTimers[menu.id][parentID] = setTimeout(() => {
					anchor.dispatchEvent(this.events["bm-dropdown-close"]);
				}, this.dropdownTimeout);
			},
			{ once: true }
		);
	}

	// Close dropdown on scroll
	window.addEventListener(
		"scroll",
		() => {
			anchor.dispatchEvent(this.events["bm-dropdown-close"]);
		},
		{ passive: true }
	);
}

function dropdownArrowEventHandler(event) {
	event.preventDefault();

	const parent = event.target.closest("li");
	const anchor = parent.querySelector("a");

	if (anchor.hasAttribute("data-bm-active")) {
		anchor.dispatchEvent(this.events["bm-dropdown-close"]);
	} else {
		anchor.dispatchEvent(this.events["bm-dropdown-open"]);
	}
}

function closeDropdownEvent(event) {
	const anchor = event.target;
	const parent = anchor.parentNode;

	const dropdowns = parent.querySelectorAll("ul");

	dropdowns.forEach((dropdown) => {
		const parentLi = dropdown.parentNode;
		const dropdownAnchor = parentLi.querySelector("a");
		dropdown.removeAttribute("data-bm-active");
		dropdownAnchor.setAttribute("aria-expanded", "false");
		dropdownAnchor.removeAttribute("data-bm-active");
	});
}

function openDropdownEvent(event) {
	const anchor = event.target;
	const dropdown = anchor.parentNode.querySelector("ul");

	dropdown.setAttribute("data-bm-active", "");
	anchor.setAttribute("aria-expanded", "true");
	anchor.setAttribute("data-bm-active", "");
	anchor.setAttribute("data-bm-clicked-once", "")
}

export { openDropdownEvent, closeDropdownEvent, dropdownHandler };
export default dropdownHandler;
