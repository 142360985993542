import Swiper, { Navigation, Pagination, Mousewheel, Lazy, A11y } from "swiper";

Swiper.use([Navigation, Pagination, Lazy, A11y]);
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
class HomeSlider {
	constructor() {
		this.slider = document.querySelector("#home-slider");
		this.sliderEl = null;

		if (this.slider) {
			this.run();
		}
	}

	run() {
		this.sliderEl = this.slider.querySelector(".swiper");

		this.swiperInstance = new Swiper(this.sliderEl, {
			lazy: {
				checkInView: true,
				loadPrevNext: true,
				preloadImages: true,
				loadOnTransitionStart: true,
				loadPrevNextAmount: 1,
			},
			spaceBetween: 30,
			direction: "horizontal",
			slidesPerView: 1,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 40,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}
}

export default HomeSlider;
