import Swiper, { Navigation, Pagination, Mousewheel, Lazy, A11y, Autoplay, FreeMode } from "swiper";

Swiper.use([Navigation, Pagination, Lazy, A11y, Autoplay, FreeMode]);
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
class HomeSlider {
	constructor() {
		this.slider = document.querySelector("#partner-slider");
		this.sliderEl = null;

		if (this.slider) {
			this.run();
		}
	}

	run() {
		this.sliderEl = this.slider.querySelector(".swiper");

		this.swiperInstance = new Swiper(this.sliderEl, {
			lazy: {
				checkInView: true,
				loadPrevNext: true,
				preloadImages: true,
				loadOnTransitionStart: true,
				loadPrevNextAmount: 1,
			},
			spaceBetween: 30,
			//slidesPerView: "auto",
			freeMode: {
				enabled: true,
				sticky: false,
			},
			speed: 5000,
			loop: true,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
			},
			direction: "horizontal",
			slidesPerView: 2,
			breakpoints: {
				768: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: "auto",
					spaceBetween: 40,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}
}

export default HomeSlider;
